.App {
  background-color: #f7f7f7;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

.card-username,
.feedback-title {
  font-weight: 700 !important;
}

.MuiCard-root {
  box-shadow: unset !important;
}

.MuiIconButton-root {
  padding: 8px !important;
}

.MuiTypography-button {
  text-transform: unset !important;
}

.MuiAutocomplete-listbox {
  margin: 0px !important;
  padding: 0px !important;
}

.MuiAutocomplete-noOptions,
.MuiAutocomplete-popupIndicator,
.SearchPlaceDialog .MuiAutocomplete-endAdornment {
  display: none !important;
}

.MuiAutocomplete-endAdornment {
  top: calc(50% - 18px) !important;
}

* {
  font-family: 'Open Sans', sans-serif !important;
}

/* 
.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}
.MuiButtonBase-root::-moz-focus-inner {
  border-style: none;
}
.MuiButtonBase-root.Mui-disabled {
  cursor: default;
  pointer-events: none;
}
@media print {
  .MuiButtonBase-root {
    -webkit-print-color-adjust: exact;
  }
} */

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  /* margin-top: 4px; */
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
/* .my-masonry-grid_column > div { */
  /* change div to reference your elements you put in <Masonry> */
  /* margin-bottom: 8px; */
/* } */

#viewerBackdrop {
  background: rgba(51, 51, 51, 0.9);
}

#viewerBackdrop svg {
  height: 20px;
  width: 20px;
}

#viewerBackdrop > div:nth-child(1) > div:nth-child(1) > button {
  display: none;
}

.arrow_12iocnn-o_O-arrow__direction__left_lg4jz7-o_O-arrow__size__medium_5gm619 {
  margin-left: -6px;
}

.arrow_12iocnn-o_O-arrow__direction__right_6eoou0-o_O-arrow__size__medium_5gm619 {
  margin-right: -6px;
}
